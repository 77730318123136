


















































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import LazyImg from './LazyImage.vue'
import Spinner from './Spinner.vue'

import ScrollTrigger from '@terwanerik/scrolltrigger'

@Component({
  components: { LazyImg, Spinner },
})
export default class App extends Vue {
  async mounted() {
    await this.$loadScript('/js/webflow.js')

    const trigger = new ScrollTrigger()
    trigger.add('[data-trigger]')
  }
}
